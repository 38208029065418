<template>
  <div class="is_pagination">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :pager-count="5"
      background
      :page-sizes="[2,10, 20, 50, 100]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["page", "total", "size"],
  watch: {},
  computed: {},
  methods: {
    //   每页条数改变时
    handleSizeChange(val) {
      this.$emit("handleSizeChange", parseInt(val));
    },
    //   改变页数时
    handleCurrentChange(val) {
      this.$emit("handleCurrentChange", parseInt(val));
    },
  },
};
</script>

<style scoped>
.is_pagination {
  padding: 24px 0 4px 0px;
  background: #fff;
  text-align: right;
}
</style>
