<template>
  <div class="modal_assembly">
    <el-dialog :title="obj.title" :visible.sync="obj.dialog" show-close :width="obj.width||'692px'"
      :before-close="cancel" :close-on-click-modal="false">
      <el-form :model="obj.form" status-icon :ref="obj.ref">
        <div class="slot">
          <slot></slot>
        </div>
        <el-form-item class="btn_div">
          <el-button size="small" type="primary" class="btn" @click="submit()"  v-if="!obj.hidOk">{{obj.okText||"保存"}}</el-button>
          <el-button size="small" type="primary" class="btn" @click="otherSubmit()" plain v-if="obj.otherText">{{obj.otherText }}</el-button>
          <el-button class="btn" size="small" @click="cancel()" v-if="!obj.hidCancel">{{obj.cancelText||"返回"}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {}
    },
    props: ["obj"],
    mounted() {},
    methods: {
      cancel() {
        this.$emit("cancel")
        this.$parent[this.obj.dialogText] = false
      },
      otherSubmit(){
        this.$emit("otherSubmit")
      },
      submit() {
        this.$emit("submit")
      }
    }

  }

</script>

<style lang="less">
  .modal_assembly {
.el-dialog__header {
    padding: 20px 32px 10px;
    .el-dialog__title{
      font-weight: 500;
    }
    .el-dialog__headerbtn{
      font-size: 31px;
    }
}
    .el-dialog__body {
      padding: 0px;

      .slot {
        padding: 10px 70px 0px 32px;
        max-height: 508px;
        overflow-y: auto;
      }

      .btn_div {
        text-align: center;
        padding: 5px 20px 24px 20px;

        button {
          padding: 9px 14px;
        }
      }
    }
  }

</style>
